import React, { useEffect, useState } from "react";
import "../css/dashboard.scss";
import Header from "../components/header/Header";
import { getPartnerEpisodeView, getPartnerFilmView } from "../services/partner";
import { useParams } from "react-router-dom";

const EpisodeDashboard = () => {
  const [data, setData] = useState([]);

  const {seasonId} = useParams();

  useEffect(() => {
    getPartnerEpisodeView(seasonId)
      .then((res) => {
        setData(res.data.sort((a, b) => new Date(a.add_date) - new Date(b.add_date)));
      });
  }, []);

  return (
    <div className="box">
      <Header />
      <div className="filter"></div>
      <div className="all-center">
        <div className="box-table">
          <table>
            <tbody>
              <tr className="text-center">
                <th>IMAGE</th>

                <th>NAME</th>

                <th>DATE</th>

                <th>VIEWS COUNT</th>
              </tr>

              {data.map((row) => (
                <tr key={row.id}>
                  <td>
                    <img
                      width={60}
                      src={
                        "https://metaflixassets.fra1.cdn.digitaloceanspaces.com/" +
                        row.main_picture
                      }
                      alt=""
                    />
                  </td>
                  <td>{row.name}</td>
                  <td> {new Date(row.add_date).toDateString()}</td>
                  <td>{row.hit}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EpisodeDashboard;

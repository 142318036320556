import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../image/logo.png";
import "./header.scss";
import { getUser } from "../../services/partner";

const Header = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    getUser()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
      });
  }, []);

  return (
    <header>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo">
            <Link to={"/dashboard"}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="menu">
            <div className="dropdown">
              <button
                className="dropdown-toggle btn1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user.name} {user.surname}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/">
                    Logout
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/payment">
                    Payment table
                  </Link>
                </li>
                <li>
                <Link to={"/dashboard"} className="dropdown-item">
                    Dashboard
                  </Link>
                </li>
                <li>
                <Link to={"/viewsDashboard"} className="dropdown-item">
                    Film Dashboard
                  </Link>
                </li>
                <li>
                <Link to={"/serieDashboard"} className="dropdown-item">
                    Serie Dashboard
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

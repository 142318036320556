import React, { useEffect, useState } from "react";
import "../css/dashboard.scss";
import Header from "../components/header/Header";
import { getPartnerFilmView, getPartnerSerieView } from "../services/partner";
import { Link } from "react-router-dom";

const SerieDashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getPartnerSerieView()
      .then((res) => {
        setData(res.data.data);
      });
  }, []);

  return (
    <div className="box">
      <Header />
      <div className="filter"></div>
      <div className="all-center">
        <div className="box-table">
          <table>
            <tbody>
              <tr className="text-center">
                <th>IMAGE</th>

                <th>NAME</th>

                <th>DATE</th>

                <th>PRICE FOR VIEW</th>

                <th>VIEWS COUNT</th>

                <th>PARTNER'S PAYMENT</th>
                <th>EPISODES</th>
              </tr>

              {data.map((row) => (
                <tr key={row.id}>
                    <td>
                      <img
                        width={60}
                        src={
                                "https://metaflixassets.fra1.cdn.digitaloceanspaces.com/" +
                                row.main_picture
                            }
                            alt=""
                            />
                        </td>
                        <td>{row.name}</td>
                        <td> {new Date(row.add_date).toDateString()}</td>
                        <td>{row.cash} AZN </td>
                        <td>{row.hit}</td>
                        <td>{row.cash * row.hit} AZN</td>
                        <td className="d-flex flex-column gap-2 justify-content-center align-items-center" style={{minHeight: 100}}>
                          {row.seasons.map((season) => (
                            <Link to={`/episodeDashboard/${season._id}`} key={season._id}>
                              <button>SEASON {season.name}</button>
                            </Link>
                          ))}
                        </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SerieDashboard;

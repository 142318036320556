import iaxios from "./iaxios";


export function getUser() {
    const token = localStorage.getItem("token");
    return iaxios.get("/user/me", {
        headers: {
            "x-access-token": token,
        },
    });
}

export function getPartnerFilms() {
    const token = localStorage.getItem("token");
    return iaxios.get("/partner/film/AZ", {
        headers: {
            "x-access-token": token,
        },
    });
}

export function loginPartner(email, password) {
    return iaxios.post("/partner/login", {
        email,
        password,
    });
}


export function getPartnerPayments(page) {
    const token = localStorage.getItem("token");
    return iaxios.get(`/partner/getallpaymentsbypartner?page=${page}&take=8`, {
        headers: {
            "x-access-token": token,
        },
    });
}


export function getPartnerFilmView() {
    const token = localStorage.getItem("token");
    return iaxios.get("/partner/view/film/AZ", {
        headers: {
            "x-access-token": token,
        },
    });
}
export function getPartnerSerieView() {
    const token = localStorage.getItem("token");
    return iaxios.get("/partner/serie/AZ", {
        headers: {
            "x-access-token": token,
        },
    });
}

export function getPartnerEpisodeView(seasonId) {
    const token = localStorage.getItem("token");
    return iaxios.get(`/partner/episodes?lang=AZ&season_id=${seasonId}`, {
        headers: {
            "x-access-token": token,
        },
    });
}

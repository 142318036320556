import React, { useEffect, useState } from "react";
import "../css/dashboard.scss";
import Header from "../components/header/Header";
import { TableRow } from "@mui/material";
import { getPartnerFilms } from "../services/partner";
const Dashboard = () => {
  const [data, setData] = useState([]);
  const [statistics, setStatistic] = useState([]);

  useEffect(() => {
    getPartnerFilms()
      .then((res) => {
        setData(res.data.data);
        setStatistic(res.data);
      });
  }, []);

  return (
    <div className="box">
      <Header />
      <div className="filter"></div>
      <div className="all-center">
        <div className="box-table">
          <table>
            <tbody>
              <tr className="text-center">
                <th>IMAGE</th>

                <th>NAME</th>

                <th>DATE</th>

                <th>PRICE</th>

                <th>PARTNER INTEREST</th>

                <th>SALES AMOUNT</th>

                <th>TOTAL PRICE</th>

                <th>PARTNER'S PAYMENT</th>
              </tr>

              {data.map((row) => (
                <tr key={row.id}>
                  <td>
                    <img
                      width={60}
                      src={
                        "https://metaflixassets.fra1.cdn.digitaloceanspaces.com/" +
                        row.main_picture
                      }
                      alt=""
                    />
                  </td>
                  <td>{row.name}</td>
                  <td> {new Date(row.add_date).toDateString()}</td>
                  <td>{row.price} AZN </td>
                  <td>{row.percent} %</td>
                  <td>{row.sale_count}</td>
                  <td>{row.sum} AZN</td>
                  <td>{row.part} AZN</td>
                </tr>
              ))}

              <TableRow className="statis-box">
                <h5 className="txt1"> Ümumi : {statistics.payment_sum} </h5>
                <h5 className="txt2"> Ödənildi : {statistics.partner_payed} </h5>
                <h5 className="txt3"> Qalıq : {statistics.residual} </h5>
              </TableRow>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

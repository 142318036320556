import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.scss";
import Login from "./screen/Login";
import Dashboard from "./screen/Dashboard";
import Protected from "./Protected";
import Payment from "./screen/Payment";
import ViewDashboard from "./screen/ViewDashboard";
import { useEffect } from "react";
import SerieDashboard from "./screen/SerieDashboard";
import EpisodeDashboard from "./screen/EpisodeDashboard";

function App() {


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/payment" element={<Payment />} />
          <Route
            path="/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/viewsDashboard"
            element={
              <Protected>
                <ViewDashboard />
              </Protected>
            }
          />
          <Route
            path="/serieDashboard"
            element={
              <Protected>
                <SerieDashboard />
              </Protected>
            }
          />
          <Route
            path="/episodeDashboard/:seasonId"
            element={
              <Protected>
                <EpisodeDashboard />
              </Protected>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
